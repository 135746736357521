export default {
  namespaced: true,
  state: {
    popupStatus: false
  },
  getters: {
    popupStatus: state => state.popupStatus
  },
  actions: {
    popupChangeStatus({ commit }, status) {
      commit('POPUP_CHANGE_STATUS', status)
    }
  },
  mutations: {
    POPUP_CHANGE_STATUS(state, status) {
      state.popupStatus = status
    }
  }
}
